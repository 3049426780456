import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Logo = ({ image, image2, newClass }) => {
  const [isLogo, setIsLogo] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Verifique a posição da página
      if (window.scrollY > 200) { // Exemplo: trocar de logo quando a página rolar 200 pixels para baixo
        setIsLogo(false);
      } else {
        setIsLogo(true);
      }
    };

    // Adicione um ouvinte de evento de rolagem
    window.addEventListener('scroll', handleScroll);

    // Remova o ouvinte de evento quando o componente for desmontado
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className={`logo ${newClass}`}>
      <Link to={process.env.PUBLIC_URL + "/"}>
        {isLogo ? ( <img
          className="logo-light"
          src={process.env.PUBLIC_URL + image2}
          alt="Logo Wolcoff"
          style={{ maxHeight: "65px" }}
        />) :  <img
        className="logo-light"
        src={process.env.PUBLIC_URL + image}
        alt="Logo Wolcoff"
        style={{ maxHeight: "50px" }}
      />}
       
      </Link>
    </div>
  );
};
Logo.propTypes = {
  image: PropTypes.string,
};

export default Logo;
