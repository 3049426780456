import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import Separator from "../elements/separator/Separator";
import TeamFour from "../elements/team/TeamFour";
import PricingTwo from "../elements/pricing/PricingTwo";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";

const Corporate = () => {
  return (
    <>
      <SEO title="Odontologia Wolcoff" />
      <main className="page-wrapper">
        <div className="header-transparent-with-topbar">
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>
        {/* Start Slider area  */}
        <div
          className="slider-area slider-style-2 height-950 bg_image"
          data-black-overlay="2"
          style={{
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/back_off.jpg)`,
          }}
        >
          <div className="overlay"></div>

          <div className="container">
            <div className="row">
              <div
                className="col-lg-12"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <div className="inner text-start odonto-header">
                  {/* <h4
                    className="subtitle"
                    style={{ backgroundColor: "#7c4d93" }}
                  >
                    <span className="theme-gradient">
                      Clínica Odontológica WOLCOFF
                    </span>
                  </h4> */}
                  <div
                    style={{
                      // fontStyle: "italic",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0",
                      width: "100%",
                    }}
                  >
                    <h1 id="titlewolcoff" className="title display-one">
                      <span
                        className="title-span"
                        style={{
                          fontSize: "15px",
                          position: "absolute",
                          top: "1px",
                        }}
                      >
                        Odontologia
                      </span>
                      <span
                        className="wolcoff-title"
                        style={{
                          color: "#7c4d93",
                          fontSize: "1em",
                          fontFamily: "Dolphian",
                          fontWeight: "500",
                        }}
                      >
                        WOLCOFF
                      </span>
                    </h1>
                  </div>
                  <ul className="list-icon">
                    <li style={{ color: "white" }}>
                      <span
                        className="icon"
                        style={{ color: "white", backgroundColor: "#7c4d93" }}
                      >
                        <FiCheck />
                      </span>{" "}
                      Tratamentos avançados.
                    </li>
                    <li style={{ color: "white" }}>
                      <span
                        className="icon"
                        style={{ color: "white", backgroundColor: "#7c4d93" }}
                      >
                        <FiCheck />
                      </span>{" "}
                      Serviços de alta qualidade.
                    </li>
                    <li style={{ color: "white" }}>
                      <span
                        className="icon"
                        style={{ color: "white", backgroundColor: "#7c4d93" }}
                      >
                        <FiCheck />
                      </span>{" "}
                      Autoestima renovada.
                    </li>
                  </ul>
                  <div className="button-group mt--40 mt_sm--20">
                    <a href="https://api.whatsapp.com/send?phone=5541997679426&text=Tenho%20interesse%20em%20agendar%20com%20vocês!">
                      <button
                        className="btn-default btn-icon"
                        target="_blank"
                        style={{ backgroundColor: "#7c4d93" }}
                      >
                        Agende agora{" "}
                        <i className="icon">
                          <FiArrowRight />
                        </i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider area  */}
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Sorria com confiança"
                  title="Transforme seu sorriso hoje!"
                  description="Melhore sua autoestima com um sorriso deslumbrante."
                />
              </div>
            </div>
            <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />
        <AboutTwo />
        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-counterup-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Números"
                  title="Amamos o que fazemos."
                  description=""
                />
              </div>
            </div>
            <CounterUpTwo
              column="col-lg-3 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-2"
              textALign="text-center"
            />
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="wrapper plr--65 plr_md--15 plr_sm--15">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Nossa Equipe"
                  title="Conheça nossa equipe de especialistas apaixonados por sorrisos, prontos para transformar o seu."
                  description=""
                />
              </div>
            </div>
            <TeamFour
              column="col-lg-6 col-xl-2 col-md-6 col-12 mt--30"
              teamStyle="team-style-three"
            />
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />
        {/* Start Elements Area  */}'
        {/* <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--35">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Pricing"
                  title="Our Pricing Plan."
                  description=""
                />
              </div>
            </div>
            <PricingTwo />
          </div>
        </div> */}
        {/* <div className="main-content"> */}
        {/* <div className="rwt-portfolio-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Serviços"
                  title="Nossas Especialidades Odontológicas!"
                  description=""
                />
              </div>
            </div>
            <PortfolioOne Column="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio" />
          </div>
        </div> */}
        {/* </div> */}
        {/* End Elements Area  */}
        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Call To Action Area  */}
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Corporate;
