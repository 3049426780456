import React from "react";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from "./itemProp/BlogProp";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import Copyright from "../../common/footer/Copyright";

const BlogGridView = () => {
  return (
    <>
      <SEO title="Blog" />
      <div
        className="header-transparent-with-topbar"
        
      >
        <HeaderOne
          btnStyle="btn-small btn-icon"
          HeaderSTyle="header-not-transparent"
        />
      </div>

      <BreadcrumbOne
        title="Fique por dentro de tudo sobre <br/>odontologia com nosso Blog"
        rootUrl="/"
        parentUrl="Home"
        currentUrl="Blog"
      />
      <div className="main-content">
        {/* Start Blog Area  */}
        <div className="rn-blog-area rn-section-gap">
          <div className="container">
            <div className="row mt_dec--30">
              <BlogProp
                column="col-lg-4 col-md-6 col-12 mt--30"
                StyleVarProp="box-card-style-default"
              />
            </div>
          </div>
        </div>
        {/* End Blog Area  */}
      </div>
      <FooterOne />
      <Copyright />
    </>
  );
};
export default BlogGridView;
