import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import HeaderTopBar from "../../common/header/HeaderTopBar";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import Copyright from "../../common/footer/Copyright";
import BrandOne from "./BrandOne";
import BrandTwo from "./BrandTwo";
import BrandThree from "./BrandThree";
import BrandFour from "./BrandFour";
import Separator from "../separator/Separator";

const Brand = () => {
  return (
    <>
      <SEO title="Wolcoff || Parceiros " />
      <div
        className="header-transparent-with-topbar"
        style={{ backgroundColor: "transparent" }}
      >
        <HeaderTopBar />
        <HeaderOne
          btnStyle="btn-small btn-icon"
          HeaderSTyle="header-not-transparent"
        />
      </div>
      <Separator />
      <div className="main-content" style={{ marginTop: "8rem" }}>
        <div className="rwt-brand-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Parceiros"
                  title="Nossos parceiros"
                  description="Na Wolcoff Odontologia, acreditamos na importância da colaboração e parceria para oferecer os melhores cuidados odontológicos aos nossos pacientes. Estamos orgulhosos de apresentar nossos valiosos parceiros, cujo compromisso com a excelência e o bem-estar de nossos pacientes é uma parte fundamental de nossa jornada. Juntos, trabalhamos incansavelmente para garantir que você receba o atendimento odontológico de alta qualidade que merece."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt--40">
                <BrandOne brandStyle="brand-style-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
      <Copyright />
    </>
  );
};
export default Brand;
