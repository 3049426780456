import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

const Result = ({ success }) => {
    return (
        <p className={success ? "success-message" : "error-message"}>
            {success ? "Sua mensagem foi enviada com sucesso." : "Ocorreu um erro ao enviar a mensagem."}
        </p>
    );
};

function ContactForm({ formStyle }) {
    const [result, setResult] = useState({ success: false, error: false });

    const formRef = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_9itshwl', 'template_5zory5f', formRef.current, 'UDd8kCDYeoyRHPhWs')
            .then((response) => {
                console.log('E-mail enviado com sucesso!', response);
                setResult({ success: true, error: false });
            })
            .catch((error) => {
                console.error('Ocorreu um erro ao enviar o e-mail:', error);
                setResult({ success: false, error: true });
            });

        setTimeout(() => {
            setResult({ success: false, error: false });
        }, 4000);
    };

    return (
        <form ref={formRef} className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="name"
                    placeholder="Seu nome"
                    required
                    style={{ color: '#7c4d93' }}
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="assunto"
                    placeholder="Assunto"
                    required
                    style={{ color: '#7c4d93' }}
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Endereço de E-mail"
                    required
                    style={{ color: '#7c4d93' }}
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="telefone"
                    placeholder="Telefone"
                    required
                    style={{ color: '#7c4d93' }}
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Sua mensagem"
                    required
                    style={{ color: '#7c4d93' }}
                />
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">Enviar</button>
            </div>

            <div className="form-group">
                {result.error ? <Result success={false} /> : null}
                {result.success ? <Result success={true} /> : null}
            </div>
        </form>
    );
}

export default ContactForm;
