import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import Separator from "../elements/separator/Separator";
import TeamFour from "../elements/team/TeamFour";
import PricingTwo from "../elements/pricing/PricingTwo";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";

const Corporate = () => {
  return (
    <>
      <SEO title="Wolcoff || Especialidades" />
      <main className="page-wrapper">
        <div
          className="header-transparent-with-topbar"
          style={{ backgroundColor: "transparent" }}
        >
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>
        {/* End Elements Area  */}
        <Separator />
        <div className="main-content" style={{ marginTop: "8rem" }}>
          <div className="rwt-portfolio-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Serviços"
                    title="Nossas Especialidades Odontológicas"
                    description=""
                  />
                </div>
              </div>
              <PortfolioOne Column="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio" />
            </div>
          </div>
        </div>
        {/* Start Elements Area  */}
        <Separator />
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Corporate;
