import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { FiPlay } from "react-icons/fi";

const PopupContent =  {
    image: "videos/capadovideo.png",
    video: "videos/videoodonto.mov", // Caminho para o vídeo local
}

function VideoOne() {
    const [toggler, setToggler] = useState(false);
    return (
        <div className="row row--15">
            <div className="col-lg-12 col-md-12 col-12 mt--30">
                <div className="video-popup icon-center">
                    <div className="thumbnail">
                        <img className="radius-small" src={`${PopupContent.image}`} alt="Corporate Image" />
                    </div>
                    <div className="video-icon">
                        <button className="btn-default rounded-player" onClick={() => setToggler(!toggler)}><span><FiPlay /></span></button>
                    </div>
                    <FsLightbox toggler={toggler} sources={[PopupContent.video]} />
                </div>
            </div> 
        </div>
    )
}
export default VideoOne;
