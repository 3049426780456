import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PortfolioItem = ({ portfolio }) => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const itemId = parseInt(hash.substring(1), 10);
      const itemElement = document.getElementById(itemId);
      if (itemElement) {
        itemElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  return (
    <div className="rwt-card" id={portfolio.id}>
      <div className="inner">
        <div className="thumbnail">
          <figure className="card-image">
            <img
              className="img-fluid"
              src={portfolio.portfolioImage}
              alt="Portfolio-01"
            />
          </figure>
        </div>
        <div className="content">
          <h5 className="title mb--10" style={{ color: "#7c4d93" }}>
            {portfolio.title}
          </h5>
          <span style={{ color: "black" }} className="subtitle b2">
            {portfolio.category}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PortfolioItem;
