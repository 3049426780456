import { useState, useRef } from "react";
import {
  FiFacebook,
  FiInstagram,
  FiMapPin,
  FiMenu,
  FiPhone,
} from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import HeaderTopBar from "./HeaderTopBar";
// import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import { Link } from "react-router-dom";

const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;
  const { clientHeight } = ref;

  const checkChange = (value) => {
    setCheck(value);
  };
  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}

      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-2 col-md-6 col-4">
              <Logo
                image={`${process.env.PUBLIC_URL}/images/logo/logo_wolcoffee.png`}
                image2={`${process.env.PUBLIC_URL}/images/logo/logo_wolcoffee.png`}
                newClass='logo-desktop'
              />
              <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
            </div>
            <div className="col-lg-10 col-md-6 col-8 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    gap: "2rem",
                    top: "2px",
                    right: "0",
                    left: "0",
                    bottom: "0",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  id="nav"
                >
                  <a
                    href="https://www.facebook.com/odontologiawolcoff"
                    target="_blank" rel="noreferrer"
                  >
                    <FiFacebook
                      style={{
                        color: "white",
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  </a>
                  <a
                    href="\"
                    target="_blank" rel="noreferrer"
                  >
                    <FiInstagram
                      style={{
                        color: "white",
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  </a>
                </div>
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/logo_wolcoffee.png`}
                  image2={`${process.env.PUBLIC_URL}/images/logo/logo_wolcoffee.png`}
                  newClass='logo-mobile'
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderOne;
