import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Nav = () => {

  const [isLogo, setIsLogo] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Verifique a posição da página
      if (window.scrollY > 200) { // Exemplo: trocar de logo quando a página rolar 200 pixels para baixo
        setIsLogo(false);
      } else {
        setIsLogo(true);
      }
    };

    // Adicione um ouvinte de evento de rolagem
    window.addEventListener('scroll', handleScroll);

    // Remova o ouvinte de evento quando o componente for desmontado
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <ul className="mainmenu">
      <li>
        
        <Link style={
          isLogo
            ? { fontSize: "13px", fontWeight: "bold" }
            : { fontSize: "13px", fontWeight: "bold", color: '#7c4d93' }
        } to="/">
          Home
        </Link>
        {/* <ul className="submenu">
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/business-consulting">Business Consulting</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/business-consulting-2">Business Consulting 02</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/corporate">Corporate</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/business">Business</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/digital-agency">Digital Agency</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/finance">Finance</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/company">Company</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/marketing">Marketing Agency</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/travel-agency">Travel Agency</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/consulting">Consulting</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/seo-agency">SEO Agency</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/personal-portfolio">Personal Portfolio</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/event-conference">Event Conference</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/creative-portfolio">Creative Portfolio</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/freelancer">Freelancer</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/international-consulting">International Consulting</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/startup">Startup</Link>
          </li>
          <li>
            <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/web-agency">Web Agency</Link>
          </li>
        </ul> */}
      </li>

      <li>
        <Link style={
          isLogo
            ? { fontSize: "13px", fontWeight: "bold" }
            : { fontSize: "13px", fontWeight: "bold", color: '#7c4d93' }
        } to="/sobre">
          Sobre Nós
        </Link>
      </li>

      <li className="with-megamenu">
        {/* <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="#">Elements</Link>
        <div className="rn-megamenu">
          <div className="wrapper">
            <div className="row row--0">
              <div className="col-lg-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/button">Button</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/service">Service</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/counter">Counter</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/progressbar">Progressbar</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/accordion">Accordion</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/social-share">Social Share</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/blog-grid">Blog Grid</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/team">Team</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/portfolio">Portfolio</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/testimonial">Testimonial</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/timeline">Timeline</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/tab">Tab</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/split">Split Section</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/call-to-action">Call To Action</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/video-popup">Video</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/brand">Brand</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/portfolio">Portfolio</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/error">404</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/advance-tab">
                      Advance Tab <span className="rn-badge-card">Hot</span>
                    </Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="#">
                      Brand Carousel{" "}
                      <span className="rn-badge-card">Comming</span>
                    </Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="advance-pricing">
                      Advance Pricing <span className="rn-badge-card">Hot</span>
                    </Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/portfolio-details/1">Portfolio Details</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/blog-details/1">Blog Details</Link>
                  </li>
                  <li>
                    <Link style={{ fontSize: "13px", fontWeight: "bold" }} to="/error">404 Page</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </li>

      <li className="has-droupdown">
        <Link style={
          isLogo
            ? { fontSize: "13px", fontWeight: "bold" }
            : { fontSize: "13px", fontWeight: "bold", color: '#7c4d93' }
        } to="especialidades">
          Especialidades
        </Link>
        <ul className="submenu">
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#"
            >
              Todos
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#2"
            >
              Clínica Geral
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#4"
            >
              Dentística Estética
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#5"
            >
              Endodontia (Canal)
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#6"
            >
              Periodontia
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#7"
            >
              Dor Orofacial / DTM
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#8"
            >
              Bucomaxilofacial
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#9"
            >
              Implantodontia
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#10"
            >
              Ortodontia
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#11"
            >
              Prótese Dental
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#12"
            >
              Odontopediatria
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#13"
            >
              Check-up Digital
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#14"
            >
              Sedação Odontológica
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#15"
            >
              Harmonização Facia
            </Link>
          </li>
          <li>
            <Link
              style={{ fontSize: "13px", fontWeight: "bold" }}
              to="/especialidades#1"
            >
              Toxina Botulínica
            </Link>
          </li>
        </ul>
      </li>

      <li>
        <Link style={
          isLogo
            ? { fontSize: "13px", fontWeight: "bold" }
            : { fontSize: "13px", fontWeight: "bold", color: '#7c4d93' }
        } to="/equipe">
          Equipe
        </Link>
      </li>

      <li>
        <Link
          style={
            isLogo
              ? { fontSize: "13px", fontWeight: "bold" }
              : { fontSize: "13px", fontWeight: "bold", color: '#7c4d93' }
          }
          to="/depoimentos"
        >
          Depoimentos
        </Link>
      </li>
      <li>
        <Link style={
          isLogo
            ? { fontSize: "13px", fontWeight: "bold" }
            : { fontSize: "13px", fontWeight: "bold", color: '#7c4d93' }
        } to="/parceiros">
          Parceiros
        </Link>
      </li>
      <li>
        <Link style={
          isLogo
            ? { fontSize: "13px", fontWeight: "bold" }
            : { fontSize: "13px", fontWeight: "bold", color: '#7c4d93' }
        } to="/blog">
          Blog
        </Link>
      </li>

      <li>
        <Link style={
          isLogo
            ? { fontSize: "13px", fontWeight: "bold" }
            : { fontSize: "13px", fontWeight: "bold", color: '#7c4d93' }
        } to="/contato">
          Contato
        </Link>
      </li>
     
    </ul>
  );
};
export default Nav;
