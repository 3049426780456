import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import Separator from "../elements/separator/Separator";
import TeamFour from "../elements/team/TeamFour";
import PricingTwo from "../elements/pricing/PricingTwo";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";

const Corporate = () => {
  return (
    <>
      <SEO title="Wolcoff || Equipe" />
      <main className="page-wrapper">
        <div
          className="header-transparent-with-topbar"
          style={{ backgroundColor: "transparent" }}
        >
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>

        <Separator />
        {/* Start Elements Area  */}

        <div className="" style={{ marginTop: "8rem" }}>
          <div className="rwt-team-area rn-section-gap">
            <div className="wrapper plr--65 plr_md--15 plr_sm--15">
              <div className="row mb--20">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Nossa Equipe."
                    title="Conheça nossa equipe de especialistas apaixonados por sorrisos, prontos para transformar o seu."
                    description=""
                  />
                </div>
              </div>
              <TeamFour
                column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
                teamStyle="team-style-three"
              />
            </div>
          </div>

          <Separator />

          {/* Start Call To Action Area  */}
          <div className="rwt-callto-action-area rn-section-gapBottom">
            <div className="wrapper">
              <CalltoActionFive />
            </div>
          </div>
        </div>

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Corporate;
