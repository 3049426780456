import React from "react";

const BrandList = [
  {
    name: "Espaço Wolcoff",
    image: "./images/parceiros/wolcoff.png",
    link: "",
  },
  {
    name: "Laboratório Evolux",
    image: "./images/parceiros/evolux.png",
    link: "https://www.labevolux.com.br/",
  },
  {
    name: "ASM Soluções digitais em Ortodontia e Ortopedia",
    image: "./images/parceiros/asm.png",
    link: "https://www.facebook.com/p/ASM-Solu%C3%A7%C3%B5es-em-Ortodontia-e-Ortopedia-100035187290976/?paipv=0&eav=AfazIegcPMx5m2IkSKE0PFjtNx4SxD-e4cR4o3n9wgvYajKnuFlEcr02MJZB6e4R0E0&_rdr",
  },
  {
    name: "Laboratório Studio Art Dental",
    image: "./images/parceiros/studio.png",
    link: "https://studioartdental.com.br/",
  },
  {
    name: "Unix Radiologia Odontológica",
    image: "./images/parceiros/unix.png",
    link: "https://labunix.com.br/",
  },
  {
    name: "Fibonacci Studio Dental",
    image: "./images/parceiros/fibonacci.png",
    link: "https://www.instagram.com/fibonacci.studiodental/",
  },
  {
    name: "RadioCenter Exames Odontológicos Digitais",
    image: "./images/parceiros/radio.png",
    link: "https://www.radiocentercuritiba.com.br/",
  },
  {
    name: "Buche Laboratório",
    image: "./images/parceiros/buche.jpg",
    link: "https://www.instagram.com/laboratorioaderciobuche/",
  },
];

const BrandOne = ({ brandStyle }) => {
  return (
    <ul className={`brand-list ${brandStyle}`}>
      {BrandList.map((data, index) => (
        <li key={index}>
          <a href={data.link}>
            <h4 style={{ color: "#7c4d93" }}>{data.name}</h4>
            <img
              style={{
                width: "150px",
              }}
              src={`${data.image}`}
              alt="Parceiros"
            />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default BrandOne;
