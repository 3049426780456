import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
  return (
    <div className="rwt-about-area about-style-2 rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/about/sobre.png"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--30 mt_sm--30">
            <div className="content">
              <div className="section-title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  {/* <h4 className="subtitle">
                    <span className="theme-gradient">WOLCOOFF.</span>
                  </h4> */}
                  <h2
                    className="title mt--10"
                    style={{ color: "rgb(124, 77, 147)" }}
                  >
                    Transformando Sorrisos em Obras de Arte: Descubra a
                    Excelência em Odontologia Conosco.
                  </h2>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p style={{ color: "black" }}>
                    Acreditamos que um sorriso saudável é a base para uma vida
                    plena e confiante. É por isso que adotamos uma abordagem
                    centrada no paciente em tudo o que fazemos. Nossa equipe de
                    profissionais altamente qualificados e compassivos está
                    comprometida em ouvir suas necessidades, compreender seus
                    objetivos e fornecer tratamentos odontológicos
                    personalizados que atendam a cada um de nossos pacientes.
                  </p>

                  <ul className="list-icon">
                    <li style={{ color: "black" }}>
                      <span className="icon" style={{ color: "white" }}>
                        <FiCheck />
                      </span>{" "}
                      Atendimento Personalizado.
                    </li>
                    <li style={{ color: "black" }}>
                      <span className="icon" style={{ color: "white" }}>
                        <FiCheck />
                      </span>{" "}
                      Ambiente Confortável.
                    </li>
                    <li style={{ color: "black" }}>
                      <span className="icon" style={{ color: "white" }}>
                        <FiCheck />
                      </span>{" "}
                      Tecnologia de Ponta.
                    </li>
                    <li style={{ color: "black" }}>
                      <span className="icon" style={{ color: "white" }}>
                        <FiCheck />
                      </span>{" "}
                      Experiência avançada.
                    </li>
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className="read-more-btn mt--40">
                    <Link className="btn-default btn-icon" to="/sobre">
                      Saiba mais sobre nós{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
