import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// Image Missão
import img from '../../assets/images/home/missao.png';

// Image Nossos Valores
import img1 from '../../assets/images/home/valores.png';

// Image Estrutura
import img2 from '../../assets/images/home/estrutura.png';

const TabTwoNew = () => {
  const [isImg, setIsImg] = useState(img);
  
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <img
                  className="radius-small"
                  src={isImg}
                  alt="Wolcoff"
                />
              </div>
              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="rn-default-tab style-two">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab onClick={() => setIsImg(img)}>
                        <div className="rn-tab-button">
                          <button onClick={() => setIsImg(img)}>Missão</button>
                        </div>
                      </Tab>
                      <Tab onClick={() => setIsImg(img1)}>
                        <div className="rn-tab-button">
                          <button onClick={() => setIsImg(img1)}>Nossos Valores</button>
                        </div>
                      </Tab>
                      <Tab onClick={() => setIsImg(img2)}>
                        <div className="rn-tab-button">
                          <button onClick={() => setIsImg(img2)}>Estrutura</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Nossa missão é promover a saúde bucal e a qualidade
                            de vida dos nossos pacientes, oferecendo tratamentos
                            odontológicos de excelência, com um atendimento
                            humanizado e personalizado.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            <b>Excelência:</b> Buscamos a excelência em tudo o
                            que fazemos. Comprometemo-nos a manter os mais altos
                            padrões de qualidade e profissionalismo.
                          </p>
                          <p>
                            <b>Educação:</b> Acreditamos na importância da
                            educação e prevenção. Trabalhamos com nossos
                            pacientes para capacitá-los com conhecimentos sobre
                            sua saúde bucal e como mantê-la em seu melhor
                            estado.
                          </p>
                          <p>
                            <b>Inovação:</b> Mantemo-nos atualizados com as mais
                            recentes tecnologias e técnicas odontológicas para
                            garantir que nossos pacientes recebam tratamentos de
                            última geração.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Oferecemos uma infraestrutura pensada em conforto e
                            segurança, sempre dentro dos mais rígidos padrões de
                            higiene. Nossos consultórios são equipados com
                            equipamentos de qualidade e alta tecnologia, o que
                            proporciona maior eficácia no diagnóstico e
                            tratamento.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabTwoNew;
