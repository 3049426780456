import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../elements/logo/Logo";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];
const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;
const indexThreeLink = footerIntemThree.quicklink;

const FooterOne = () => {
  return (
    <>
      <footer className="rn-footer footer-style-default">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="logo">
                    <img
                      className="logo-light"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/logo/logo_wolcoffee2.png"
                      }
                      alt="Corporate Logo"
                      style={{ maxHeight: "100px" } }
                    />
                  </div>
                  <h3 style={{ color: "white" }} className="text-big">
                    {footerIntem.title}
                  </h3>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{footerIntemOne.title}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexOneLink.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{footerIntemThree.title}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexTwoLink.map((data, index) => (
                          <li key={index}>
                            <a href={`${data.url}`} target="_blank" rel="noreferrer">
                              {data.text}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="widget-menu-bottom">
                    {/* <h4 className="title">{footerIntemFour.title}</h4> */}
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {/* {indexTwoLink.map((data, index) => (
                          <li key={index}>
                            <Link to={`${data.url}`}>{data.text}</Link>
                          </li>
                        ))} */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div  className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">{footerIntemFive.title}</h4>
                  <div className="inner">
                    <h6
                      className="subtitle"
                      style={{ padding: "0", marginBottom: "0" }}
                     
                    >
                      R. Alberto Folloni, 1712 Ahú
                    </h6>
                    <h6 className="subtitle" style={{ marginBottom: "5px" }}>
                      {footerIntemFive.subtitle}
                    </h6>
                    <h6 className="subtitle">Curitiba, Paraná</h6>
                    <div
                      className="widget-menu-bottom"
                      style={{ display: "flex", flexDirection: "column" }}
                      
                    >
                      <h4 className="title">{footerIntemTwo.title}</h4>
                      <a>Telefone Portão:  (41) 3206-4241</a>
                      <a>WhatsApp Ahú: (41) 4106-5970 </a>
                      <a>WhatsApp Portão: (41) 99767-9426</a>
                    </div>
                    <form className="newsletter-form" action="#">
                      {/* <div className="form-group">
                        <input
                          type="email"
                          placeholder="Enter Your Email Here"
                        />
                      </div>
                      <div className="form-group">
                        <button className="btn-default" type="submit">
                          Submit Now
                        </button>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterOne;
