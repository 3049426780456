import React, { useState } from "react";
import ContactForm from "./ContactForm";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
// import "./ContactOne.css"; // Importe o arquivo CSS

const ContactOne = () => {
  const [showMap1, setShowMap1] = useState(true);

  const toggleMap1 = () => {
    setShowMap1(true);
  };

  const toggleMap2 = () => {
    setShowMap1(false);
  };

  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones color="white" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Telefone</h4>
                    <p>
                      <a href="tel:+4132064241">Tel: (41) 3206-4241 - Portão</a>
                    </p>
                    <p>
                      <a href="tel:+4132064241">WhatsApp Ahú: (41) 4106-5970</a>
                    </p>
                    <p>
                      <a href="tel:+41997679426">WhatsApp Portão: (41) 99767-9426</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div id="cardcontato" className="icon">
                    <FiMail color="white" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Endereço de E-mail</h4>
                    <p>
                      <a href="mailto:admin@gmail.com">
                        odontologiawolcoff@gmail.com
                      </a>
                    </p>
                    <p>
                      <a href="mailto:example@gmail.com">&nbsp;</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin color="white" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Localização </h4>
                    <p><b>Curitiba - Paraná</b></p>
                    <p>R. Alberto Folloni, 1712 - Ahú</p>
                    <p>R. Dr. Lauro Wolff Valente, 176 - Portão</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30 col-12">
          <div className="button-container">
            <div
              className="button-container"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "2rem",
                marginBottom: "1rem",
              }}
            >
              <button
                onClick={toggleMap1}
                style={{
                  backgroundColor: "#7c4d93",
                  color: "white",
                  borderRadius: "50px",
                  padding: "10px 20px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Sede Portão
              </button>
              <button
                onClick={toggleMap2}
                style={{
                  backgroundColor: "#7c4d93",
                  color: "white",
                  borderRadius: "50px",
                  padding: "10px 20px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Sede Ahú
              </button>
            </div>
          </div>
          <div className="map-container">
            {showMap1 ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.9344963581743!2d-49.29980952481588!3d-25.47386263462821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce3692c241127%3A0xa23d88eac83a2fd6!2sRua%20Dr.%20Lauro%20Wolff%20Valente%2C%20176%20-%20Port%C3%A3o%2C%20Curitiba%20-%20PR%2C%2081070-010!5e0!3m2!1spt-PT!2sbr!4v1696516565877!5m2!1spt-PT!2sbr"
                width="600"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              <iframe
                title="Google Map 2"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.134037220489!2d-49.26491842481874!3d-25.40032353171773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce6985c6447c3%3A0xe294a56a063bb83!2sR.%20Alberto%20Folloni%2C%201712%20-%20Ah%C3%BA%2C%20Curitiba%20-%20PR%2C%2082200-230!5e0!3m2!1spt-PT!2sbr!4v1696516058130!5m2!1spt-PT!2sbr"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactOne;
