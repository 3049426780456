import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import Separator from "../elements/separator/Separator";
import TeamFour from "../elements/team/TeamFour";
import GalleryOne from "../elements/gallery/GalleryOne";
import PricingTwo from "../elements/pricing/PricingTwo";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import ScrollAnimation from "react-animate-on-scroll";

import TabTwo from "../elements/tab/TabTwo";

import ServiceOne from "../elements/service/ServiceOne";
import SectionTitleNew from "../elements/sectionTitle/SectionTitleNew";
import TabTwoNew from "../elements/tab/TabTwoNew";

const PopupData = [
  {
    id: "1",
    image: "./images/estrutura/estrutura.png",
    popupLink: ["./images/estrutura/estrutura.png"],
  },
  {
    id: "2",
    image: "./images/estrutura/estrutura1.png",
    popupLink: ["./images/estrutura/estrutura1.png"],
  },
  {
    id: "3",
    image: "./images/estrutura/estrutura6.png",
    popupLink: ["./images/estrutura/estrutura6.png"],
  },
  {
    id: "4",
    image: "./images/estrutura/estrutura3.png",
    popupLink: ["./images/estrutura/estrutura3.png"],
  },
  {
    id: "5",
    image: "./images/estrutura/estrutura4.png",
    popupLink: ["./images/estrutura/estrutura4.png"],
  },
  {
    id: "6",
    image: "./images/estrutura/estrutura5.png",
    popupLink: ["./images/estrutura/estrutura5.png"],
  },
];

const Corporate = () => {
  return (
    <>
      <SEO title="Wolcoff || Sobre" />
      <main className="page-wrapper">
        <div
          className="header-transparent-with-topbar"
          style={{ backgroundColor: "transparent" }}
        >
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>
        {/* Start Elements Area  */}

        <div className="">
          <div
            className="slider-area slider-style-1 height-850 bg_image"
            data-black-overlay="7"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/1920x.jpg)`,
            }}
          >
            <div className="container responsive-container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner pt--80 text-center">
                    <div>
                      <h3 className="rn-sub-badge">
                        <span className="theme-gradient">
                          Um pouco mais sobre nós
                        </span>
                      </h3>
                    </div>
                    <h1 className="title display-one">
                      Sua saúde bucal é o nosso compromisso
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rwt-about-area about-style-2 rn-section-gap">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="./images/team/dr_bruna-4.jpg"
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 mt_md--30 mt_sm--30">
                <div className="content">
                  <div className="section-title">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <h2 className="title mt--10" style={{ color: "#7c4d93" }}>
                        Quem Somos?
                      </h2>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <p style={{color: "black"}}>
                        Fundada em 2013, a Odontologia Wolcoff tem sido
                        referência no bairro Portão ao longo desses 10 anos e em
                        2023 expandimos nossa clínica também para o bairro Ahú.
                        Nossa equipe é composta por especialistas altamente
                        qualificados e experientes em diversas áreas da
                        odontologia.
                      </p>
                      <p style={{color: "black"}}>
                        Somos uma equipe dedicada de profissionais apaixonados
                        pela saúde bucal e comprometidos em proporcionar
                        cuidados odontológicos excepcionais para todos os nossos
                        pacientes. Na Odontologia Wolcoff, nossa missão é
                        transformar sorrisos e melhorar vidas por meio da
                        excelência em odontologia.
                      </p>
                      {/* 
                      <ul className="list-icon">
                        <li style={{ color: "white" }}>
                          <span className="icon" style={{ color: "white" }}>
                            <FiCheck />
                          </span>{" "}
                          Atendimento Personalizado.
                        </li>
                        <li style={{ color: "white" }}>
                          <span className="icon" style={{ color: "white" }}>
                            <FiCheck />
                          </span>{" "}
                          Ambiente Confortável.
                        </li>
                        <li style={{ color: "white" }}>
                          <span className="icon" style={{ color: "white" }}>
                            <FiCheck />
                          </span>{" "}
                          Tecnologia de Ponta.
                        </li>
                        <li style={{ color: "white" }}>
                          <span className="icon" style={{ color: "white" }}>
                            <FiCheck />
                          </span>{" "}
                          Experiência avançada.
                        </li>
                      </ul> */}
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >

                    </ScrollAnimation>
                  </div>
                </div>
              </div>

              {/* Tab */}
              <div className="rwt-tab-area rn-section-gap mt--40">
                <div className="container">
                  <div className="row mb--40">
                    <div className="col-lg-12">
                      <SectionTitleNew 
                        textAlign="text-center"
                        radiusRounded=""
                        subtitle="Missão"
                        title="Nossa Missão"
                        description=""
                      />
                      {/* <SectionTitle
                        textAlign="text-center"
                        radiusRounded=""
                        subtitle="Missão"
                        title="Nossa Missão"
                        description=""
                      /> */}
                    </div>
                  </div>
                  {/* <TabTwo /> */}
                  <TabTwoNew />
                </div>
              </div>

              <div className="rwt-gallery-area rn-section-gapBottom">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 mb--20">
                      <SectionTitle
                        textAlign="text-center"
                        radiusRounded=""
                        subtitle="Estrutura"
                        title="Nossa Estrutura"
                        description=""
                      />
                    </div>
                  </div>
                  <div className="row mt_dec--30 row--15">
                    {PopupData.map((item) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                        key={item.id}
                      >
                        <GalleryOne galleryItem={item} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* <div className="rn-service-area rn-section-gap ">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <SectionTitle
                        textAlign="text-center"
                        radiusRounded=""
                        subtitle="What we can do for you"
                        title="Services provide for you."
                        description=""
                      />
                    </div>
                  </div>
                  <ServiceOne
                    serviceStyle="service__style--1 bg-color-blackest radius mt--20 rbt-border"
                    textAlign="text-start"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Corporate;
