import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const Data = [
  {
    countNum: 999,
    countTitle: "Clientes Satisfeitos",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  },
  {
    countNum: 999,
    countTitle: "Procedimentos estéticos",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  },
  {
    countNum: 10,
    countTitle: "Anos de Experiências",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  },
  {
    countNum: 2,
    countTitle: "Clínicas Odontológicas",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  },
];

const CounterUpTwo = ({ textALign, counterStyle, column }) => {
  return (
    <div className="row">
      {Data.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`count-box ${counterStyle} ${textALign}`}>
            <TrackVisibility once>
              {({ isVisible }) =>
                isVisible && (
                  <div className="count-number">
                    {data.countNum === 999 ? "+" : ""}
                    {isVisible ? <CountUp end={data.countNum} /> : 0}
                  </div>
                )
              }
            </TrackVisibility>
            <h5 style={{ color: "#7c4d93" }} className="title">
              {data.countTitle}
            </h5>
            {/* <p className="description">{data.description}</p> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CounterUpTwo;
