import React from "react";
import BlogClassicData from "../data/blog/BlogList.json";
import BlogDetailsContent from "../components/blog/BlogDetailsContent";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";

const BlogDetails = ({
  match: {
    params: { id },
  },
}) => {
  const blogId = parseInt(id, 10);
  const data = BlogClassicData.filter((blog) => blog.id === blogId);
  return (
    <>
      <SEO title="Blog" />
      <div
        className="header-transparent-with-topbar"
      >
        <HeaderOne
          btnStyle="btn-small btn-icon"
          HeaderSTyle="header-not-transparent"
        />
      </div>
      <div className="rn-blog-details-area">
        <BlogDetailsContent data={data[0]} />
      </div>

      <FooterOne />
      <Copyright />
    </>
  );
};
export default BlogDetails;
