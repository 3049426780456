import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "/images/service/tecnologiadeponta.jpg",
    title: "Tecnologia de Ponta para sua Saúde Bucal",
    description:
      "Investimos em tecnologia de última geração para garantir sua saúde bucal.",
  },
  {
    image: "/images/service/equipe1.jpg",
    title: "Profissionais Altamente Qualificados",
    description:
      "Nossa equipe é composta por especialistas altamente qualificados e experientes.",
  },
  {
    image: "/images/service/sorriso.png",
    title: "Sorrisos Transformados, Vidas Melhoradas",
    description:
      "Nossa missão é transformar sorrisos e melhorar vidas por meio da excelência.",
  },
];
const ServiceTwo = ({ textAlign, cardStyle }) => {
  return (
    <div
      className="row row--15 service-wrapper"
      style={{
        gap: "2rem",
        justifyContent: "center",
      }}
    >
      {ServiceList.map((val, i) => (
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div
              className={`card-box ${cardStyle} ${textAlign}`}
              style={{ backgroundColor: "white" }}
            >
              <div className="inner">
                <div className="image">
                  <Link to="#service">
                    <img src={`${val.image}`} alt="card Images" />
                  </Link>
                </div>
                <div className="content">
                  <h4 className="title mb--20">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-black mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                  {/* <Link
                    className="btn-default btn-small btn-border"
                    to="#service"
                  >
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceTwo;
