import PropTypes from "prop-types";
import React from "react";

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title ? title : 'Wolcoff Odontologia - Transformando Sorrisos'}</title>
      <meta name="description"
        content="Wolcoff Odontologia - Transformando sorrisos com cuidado e experiência. Oferecemos serviços odontológicos de qualidade para toda a família. Agende sua consulta hoje!" />

      <meta name="keywords"
        content="odontologia, dentista, saúde bucal, ortodontia, implantes dentários, clínica odontológica, tratamento dentário" />

      <meta name="author" content="Wolcoff Odontologia" />

      <meta http-equiv="content-language" content="pt-BR" />

      <meta name="email" content="odontologiawolcoff@gmail.com" />
      <meta name="phone_number" content="+55 41997679426" />

      <meta property="og:title" content="Wolcoff Odontologia - Transformando Sorrisos" />
      <meta property="og:description"
        content="Oferecemos serviços odontológicos de qualidade para toda a família. Agende sua consulta hoje!" />
      <meta property="og:url" content="https://www.odontologiawolcoff.com.br/" />
      <meta property="og:image" content="https://www.odontologiawolcoff.com.br/images/wolcoff/wolcoff.png" />
      <meta name="robots" content="noindex, follow" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
