import React, { useState } from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Modal, Box, Typography } from "@mui/material";

const ceoData = [
  {
    image: "dr_bruna-min",
    name: "Dra. Bruna Wolcoff (CEO)",
    designation: "Manager",
    location:
      "Nossa líder visionária, guiando nossa clínica para oferecer o melhor atendimento odontológico.",
    description:
      "Sou cirurgiã-dentista graduada pela Universidade Positivo em 2013, mas atuo na área odontológica desde 2005. Sou apaixonada pela odontologia e, desde a graduação, não parei mais de me atualizar, sempre pensando no bem-estar do meu paciente. Viso sempre um atendimento humanizado e de qualidade. Sou habilitada em sedação medicamentosa, especialista em saúde coletiva e outras especialidades. Hoje, na Odontologia Wolcoff, conto com uma equipe especializada de minha inteira confiança. Tenho muito amor pela minha profissão e muito respeito pelos meus pacientes. Sei que por trás de cada sorriso que transformo, diariamente, existe uma história de vida única.\n\nDra. Bruna Wolcoff CRO 24.431 PR",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiLinkedin />,
        url: "#",
      },
    ],
  },
];

const teamData = [
  {
    image: "dr_guilherme-min",
    name: "Dr. Guilherme Pinheiro Dias",
    designation: "Sr Product Designer",
    location:
      "Um membro valioso de nossa equipe, comprometido com a excelência em odontologia.",
    description: `Sou cirurgião-dentista graduado pela Universidade do Oeste Paulista (UNOESTE) no ano de 1999. Desde então, me dedico à profissão buscando sempre me capacitar ao máximo através de cursos para proporcionar aos meus pacientes o melhor tratamento possível.

No ano de 2010 concluí a minha primeira especialização, na área de Cirurgia Bucomaxilofacial, pela Associação Brasileira de Odontologia (ABO/PR). E mais recentemente, no ano de 2023, concluí a minha segunda especialização, agora na área de Periodontia pela São Leopoldo Mandic de Curitiba.

Meu lema como profissional é o de proporcionar sempre aos meus pacientes saúde e bem-estar.

Dr. Guilherme Pinheiro Dias
CRO 17.253 PR`,
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  {
    image: "dr_ellen-min",
    name: "Dra. Ellen Yamamoto",
    designation: "Software Developer",
    location:
      "Uma profissional apaixonada pela saúde bucal e pelo bem-estar de nossos pacientes",
    description: `Sou cirurgiã-dentista graduada pela Pontifícia Universidade Católica do Paraná. Na Universidade Positivo fiz minha especialização em Endodontia no ano de 2015. Desde minha formação tenho me dedicado ao tratamento e diagnóstico das enfermidades da polpa, buscando sempre o melhor atendimento focado na saúde.

Dra Ellen Borba Yamamoto
CRO 21.898 PR`,
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  {
    image: "dr_mary-min",
    name: "Dra. Mary Stella Kivel Dias Vitório",
    designation: "Software Developer",
    location:
      "Sou cirurgiã-dentista graduada pela Universidade Positivo. Me formei em 2014 e apesar de ter me formado com 36 anos, eu já trabalhava em consultório odontológico desde os meus 18 anos, então meu amor pela odontologia era antigo.",
    description: `Sou cirurgiã-dentista graduada pela Universidade Positivo. Me formei em 2014 e apesar de ter me formado com 36 anos, eu já trabalhava em consultório dentário desde os meus 18 anos, então meu amor pela odontologia era antigo. Logo após a minha colação de grau iniciei a minha especialização em Implante na Faculdade Ilapeo, com término em 2016. Esta especialização foi muito especial, pois em toda a minha vida profissional eu já sabia a área que eu queria trabalhar e sempre tive uma meta que era devolver saúde aos meus pacientes e isso consigo devolvendo a mastigação e auto estima.

Como minha profissão é muito importante para mim e levo o melhor ao meu paciente, fiz vários cursos desde preparo dentário, enxerto gengival, bichectomia até reabilitação com implantes zigomáticos, entre outros. Trabalho a muitos anos com grandes profissionais da odontologia e sempre estou me atualizando em congressos e cursos para levar o melhor tratamento para o meu paciente.

Dra Mary Stella Kivel Dias Vitório
CRO 24.337 PR`,
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  {
    image: "dr_giovanna-min",
    name: "Dra. Giovanna Simião",
    designation: "Accounts Manager",
    location:
      "Dedicada a criar sorrisos incríveis e proporcionar cuidados excepcionais.",
    description: `Sou cirurgiã-dentista graduada pela Pontifícia Universidade Católica do Paraná em 2012. Em 2015, concluí minha especialização em ortodontia e, em 2016, me graduei mestre na mesma disciplina, também pela PUCPR. Minha paixão pela ortodontia me levou a estudar muito e a buscar o melhor tratamento ortodôntico para os meus pacientes. Por isso, me credenciei como Invisalign Top Doctor e, hoje, trabalho com a ortodontia digital. Tenho muito amor pela minha profissão e muito respeito pelos meus pacientes.

Sei que por trás de cada sorriso que transformo, diariamente, existe uma história de vida única.

Dra. Giovanna Simião
Especialista e mestre em Ortodontia
CRO 22.958 PR`,
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  {
    image: "dr_natalie-min",
    name: "Dra. Natalie Temporão",
    designation: "Accounts Manager",
    location:
      "Especialista em odontologia, contribuindo para a saúde bucal de nossos pacientes.",
    description: `Sou cirurgiã-dentista graduada pela PUC-PR em 2012. Desde o início sempre fui apaixonada pela área de periodontia e cirurgia. Após minha graduação, cursei Especialização em Periodontia em Porto Alegre/RS, na Universidade Federal do Rio Grande do Sul. Segui na área, me tornando mestre em Odontologia com Ênfase em Periodontia pela Universidade Federal do Paraná. Sou especialista em Implantodontia pelo ILAPEO, grande referência na área. Possuo também pós-graduação em cirurgia-oral menor e cirurgia periodontal e periimplantar. Sigo com meus estudos e cursos para estar sempre me atualizando, levando para meus pacientes um tratamento de excelência.

Sou extremamente grata por esta profissão maravilhosa, que me permite trazer de volta a saúde e o sorriso para as pessoas! Atendo cada um dos meus pacientes com muito carinho, respeito e transparência. E agradeço a cada um por me confiar sua saúde bucal.

Dra. Natalie Temporão
CRO 22.968 PR`,
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "#",
      },
      {
        icon: <FiTwitter />,
        url: "#",
      },
      {
        icon: <FiInstagram />,
        url: "#",
      },
    ],
  },
  // {
  //   image: "mairon-schlichting-min",
  //   name: "Mairon Schlichting",
  //   designation: "Accounts Manager",
  //   location:
  //     "Nutricionista e Personal Trainer",
  //   description: `Eu sou Mairon, um entusiasta da saúde, nutrição e esportes, com mais de 20 anos de experiência. Graduado em Educação Física e Nutrição, com especializações em Nutrição Esportiva e Fisiologia do Esporte, ofereço consultoria esportiva e orientação nutricional personalizada. Esteja você buscando promover a saúde, perder peso, ganhar massa muscular, passar por cirurgia bariátrica, adotar uma dieta vegana ou enfrentar desafios durante a gestação, estou aqui para ajudar. Minha missão é guiá-lo, independentemente da idade ou ponto de partida, em direção a uma vida mais saudável. Junte-se a mim nessa jornada emocionante para alcançar o seu melhor.`,
  //   socialNetwork: [
  //     {
  //       icon: <FiFacebook />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiTwitter />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiInstagram />,
  //       url: "#",
  //     },
  //   ],
  // },
];

const TeamFour = ({ column, teamStyle }) => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({ name: "", description: "" });

  const handleOpen = (data) => {
    setOpen(true);
    setModalData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    maxWidth: 600,
    width: "100%",
    margin: "auto",
    mt: 6,
    p: 4,
    backgroundColor: "#7c4d93",
  };

  return (
    <div
      className="row row--15"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* Renderiza a seção do CEO */}
      {ceoData.map((data, index) => (
        <div
          className="col-lg-6 col-xl-4 col-md-6 col-12 mt--30"
          key={index + 1}
        >
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`rn-team ${teamStyle}`}>
              <div className="inner">
                <figure className="thumbnail">
                  <img
                    src={`./images/team/${data.image}.jpg`}
                    alt="Equipe imagem"
                  />
                </figure>
                <figcaption className="content">
                  <div className="team-info">
                    <h2 className="title">{data.name}</h2>
                    {/* <h6 className="subtitle theme-gradient">
                      {data.designation}
                    </h6> */}
                    <div className="team-form">
                      <span className="location">{data.location}</span>
                      <Button
                        sx={{ color: "#7c4d93" }}
                        onClick={() => handleOpen(data)}
                      >
                        Ver mais...
                      </Button>
                    </div>
                  </div>
                </figcaption>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontSize: "18px",
              borderBottom: "1px solid #fff",
              paddingBottom: "4px",
            }}
            variant="h6"
            component="h2"
          >
            {modalData.name}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: "16px", color: "#fff" }}
          >
            {modalData.description.split("\n").map((paragraph, index) => (
              <p key={index} style={{ color: "#fff" }}>
                {paragraph}
              </p>
            ))}
          </Typography>
        </Box>
      </Modal>

      {/* Renderiza a seção da equipe (abaixo da seção do CEO) */}
      <div
        className="row row--15"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {teamData.map((data, index) => (
          <div
            className="col-lg-6 col-xl-4 col-md-6 col-12 mt--30"
            key={index + 1}
          >
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div
                className={`rn-team ${teamStyle}`}
                // style={{ width: "200px" }}
              >
                <div className="inner">
                  <figure className="thumbnail">
                    <img
                      src={`./images/team/${data.image}.jpg`}
                      alt="Wolcoff"
                    />
                  </figure>
                  <figcaption className="content">
                    <div className="team-info">
                      <h2 className="title">{data.name}</h2>
                      {/* <h6 className="subtitle theme-gradient">
                        {data.designation}
                      </h6> */}
                      <div className="team-form">
                        <span className="location">{data.location}</span>
                        <Button
                          sx={{ color: "#7c4d93" }}
                          onClick={() => handleOpen(data)}
                        >
                          Ver mais...
                        </Button>
                      </div>
                    </div>
                  </figcaption>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamFour;
