import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import Separator from "../elements/separator/Separator";
import TeamFour from "../elements/team/TeamFour";
import GalleryOne from "../elements/gallery/GalleryOne";
import PricingTwo from "../elements/pricing/PricingTwo";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import ScrollAnimation from "react-animate-on-scroll";

import TabTwo from "../elements/tab/TabTwo";

import ServiceOne from "../elements/service/ServiceOne";

import ContactOne from "../elements/contact/ContactOne";

const Corporate = () => {
  return (
    <>
      <SEO title="Wolcoff || Contato" />
      <main className="page-wrapper">
        <div
          className="header-transparent-with-topbar"
          style={{ backgroundColor: "transparent" }}
        >
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>
        {/* Start Elements Area  */}

        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container" style={{ marginTop: "7rem" }}>
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Contato"
                    title="Entre em contato conosco"
                    description=""
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Corporate;
