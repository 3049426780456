import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import Separator from "../elements/separator/Separator";
import TeamFour from "../elements/team/TeamFour";
import GalleryOne from "../elements/gallery/GalleryOne";
import PricingTwo from "../elements/pricing/PricingTwo";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import ScrollAnimation from "react-animate-on-scroll";

import TeamTwo from "../elements/team/TeamTwo";
import VideoOne from "../elements/video/VideoOne";

import TabTwo from "../elements/tab/TabTwo";

import ServiceOne from "../elements/service/ServiceOne";

const Corporate = () => {
  return (
    <>
      <SEO title="Wolcoff || Depoimentos" />
      <main className="page-wrapper">
        <div
          className="header-transparent-with-topbar"
          style={{ backgroundColor: "transparent" }}
        >
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>
        {/* Start Elements Area  */}

        {/* Depoimentos */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container" style={{ marginTop: "8rem" }}>
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Casos de sucessos"
                  title="Conheça nossos cases de sucesso!"
                  description=""
                />
              </div>
            </div>
            <TeamTwo
              column="col-lg-4 col-md-6 col-12 mt--10"
              teamStyle="team-style-default style-two"
            />
          </div>
        </div>

        {/* Videos */}
        <div className="main-content">
          {/* Start Elements Area  */}
          <div className="rwt-video-popup-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Video"
                    // title="Style One."
                    description=""
                  />
                </div>
              </div>
              <VideoOne />
            </div>
          </div>
          {/* End Elements Area  */}
        </div>

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Corporate;
